import {
  ActivityHotelPoi,
  ActivityMapTeaserFragmentFragment,
  ActivityTeaserFragmentFragment,
  HotelMapTeaserFragmentFragment,
  HotelTeaserFragmentFragment,
  PoiFragmentFragment,
  PoiMapTeaserFragmentFragment,
  PoiTableFragment,
  PoiTeaserFragmentFragment,
  Query,
  TeaserQueryQueryVariables,
} from '../../generated/types';
import getMapIconForCategoryKey from '../../utils/getMapiconForCategoryKey';
import { Marker, Popup } from 'react-leaflet';
import React, { useEffect, useRef, useState } from 'react';
import renderTeaser from '../../utils/render-teaser';
import { useLazyQuery } from '@apollo/client';
import { TeaserQuery } from '../../queries';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useStore } from '../../store/provider';

export type TeaserMarkerDataType =
  | ActivityHotelPoi
  | ActivityTeaserFragmentFragment
  | ActivityMapTeaserFragmentFragment
  | PoiTeaserFragmentFragment
  | PoiMapTeaserFragmentFragment
  | HotelTeaserFragmentFragment
  | HotelMapTeaserFragmentFragment
  | PoiFragmentFragment
  | PoiTableFragment;

interface TeaserMarkerProps {
  data: TeaserMarkerDataType;
  teaserProps?: any;
  onClosePopup?: () => void;
  onOpenPopup?: () => void;
  isActive?: boolean;
  lazy?: boolean;
  small?: boolean;
}

const TeaserMarker: React.FC<TeaserMarkerProps> = ({
  data,
  teaserProps,
  onClosePopup: onClosePopupCB,
  onOpenPopup: onOpenPopupCB,
  isActive,
  lazy,
  small,
}) => {
  const popupRef = useRef<Popup>(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [getLazyData, { data: lazyData }] = useLazyQuery<
    Query,
    TeaserQueryQueryVariables
  >(TeaserQuery);
  const store = useStore();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const onImageLoaded = () => {
    setIsImageLoaded(true);
  };

  const onOpenPopup = () => {
    setIsPopupOpen(true);

    onOpenPopupCB && onOpenPopupCB();
  };

  const onClosePopup = () => {
    setIsPopupOpen(false);

    onClosePopupCB && onClosePopupCB();
  };

  useEffect(() => {
    if (popupRef.current && isImageLoaded && isPopupOpen) {
      popupRef.current.leafletElement.update();

      timeoutRef.current = setTimeout(() => {
        popupRef.current.leafletElement.update();
      }, 100);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isImageLoaded, popupRef, isPopupOpen]);

  useEffect(() => {
    if (popupRef.current && isPopupOpen && lazy && !lazyData) {
      getLazyData({
        variables: {
          route: data.url,
          frontend: store.config.frontendName,
        },
      });
    }
  }, [popupRef, isPopupOpen, lazy, lazyData]);

  useEffect(() => {
    if (isPopupOpen && popupRef.current) {
      timeoutRef.current = setTimeout(() => {
        popupRef.current.leafletElement.update();
      }, 100);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isPopupOpen, popupRef]);

  if (!data.location || !data.location.lat || !data.location.lng) {
    return null;
  }

  const renderTeaserProps = {
    small: true,
    textLength: 50,
    disableLazy: true,
    onImageLoaded,
    smallMapMarker: true,
    ...teaserProps,
  };

  const categoryKey =
    data.__typename === 'Poi' && data.isPass ? 'poi_pass' : data.category?.key!;

  return (
    <Marker
      icon={getMapIconForCategoryKey(categoryKey, isActive)}
      position={data.location! as any}
      onPopupOpen={onOpenPopup}
      onPopupClose={onClosePopup}
    >
      <Popup autoPan={false} ref={popupRef} className="with-card">
        <Box>
          {lazy ? (
            lazyData ? (
              renderTeaser(
                lazyData.route!.entity as TeaserMarkerDataType,
                renderTeaserProps
              )
            ) : (
              <Flex
                minW={200}
                minH={200}
                alignItems="center"
                justifyContent="center"
                bg="white"
              >
                <Spinner />
              </Flex>
            )
          ) : (
            renderTeaser(data, renderTeaserProps)
          )}
        </Box>
      </Popup>
    </Marker>
  );
};

export default TeaserMarker;
