import { FC } from 'react';
import EntityContext from '../../context/EntityContext';
import SearchContext from '../../context/SearchContext';
import { TermCategory } from '../../generated/types';
import { Box } from '@chakra-ui/react';

interface WrapperProps {
  typename: string;
  activeCategory?: TermCategory;
}

const Wrapper: FC<WrapperProps> = ({ children, typename, activeCategory }) => {
  return <Box className={`Node--${typename}`}>{children}</Box>;
};

export default Wrapper;
