import { useEffect, useState } from 'react';

/**
 * Threshold from which mouse movement with pressed mouse button
 * is considered a drag instead of a click.
 */
const MOVE_DRAG_THRESHOLD = 10;

export default function useDragDetection(): {
    handleMouseDown: () => void;
    dragging: boolean;
} {
    const [mouseDown, setMouseDown] = useState(false);
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        let mouseMove = 0;

        function handleMouseUp(): void {
            setMouseDown(false);
        }

        function handleMouseMove(e: MouseEvent): void {
            mouseMove += Math.abs(e.movementX) + Math.abs(e.movementY);
            setDragging(mouseMove > MOVE_DRAG_THRESHOLD);
        }

        if (mouseDown) {
            document.addEventListener('mouseup', handleMouseUp);
            document.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [mouseDown]);

    function handleMouseDown(): void {
        setMouseDown(true);
        setDragging(false);
    }

    return {
        handleMouseDown,
        dragging,
    };
}
