import { Box, Img, ImgProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import getAppPublicPath from '../../utils/getAppPublicPath';
import useIsMobile from '../../hooks/useIsMobile';

type WavesProps = ImgProps & {
  flipped?: boolean;
};

const Waves: FC<WavesProps> = ({ flipped, ...props }) => {
  const isMobile = useIsMobile();
  const publicPath = getAppPublicPath();

  return (
    <Img
      zIndex={5}
      maxHeight="260px"
      width="100%"
      pos="absolute"
      htmlHeight={isMobile ? 121 : 260}
      htmlWidth={isMobile ? 360 : 1921}
      left={0}
      bottom={0}
      transform={flipped ? 'scaleX(-1) scaleY(-1)' : 'none'}
      src={`${publicPath}/${isMobile ? 'mobile-waves.png' : 'waves.png'}`}
      {...props}
    />
  );
};

export default Waves;
