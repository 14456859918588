import { Flex, ThemeProvider } from '@chakra-ui/react';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import theme from './theme';
import { MerchantMapMarkerIcon } from './icons';

export const basicIcon = new L.Icon({
  iconUrl: '/marker-icon.png',
  iconRetinaUrl: '/marker-icon-2x.png',
  iconAnchor: [12, 41],
  popupAnchor: [0, -44],
  iconSize: [25, 41],
  shadowUrl: '/marker-shadow.png',
  shadowSize: [41, 41],
  shadowAnchor: [12, 41],
});

const MarkerIcon: React.FC<{
  Icon?: any;
  isActive?: boolean;
  content?: any;
}> = ({ Icon, isActive, content }) => {
  return (
    <ThemeProvider theme={theme}>
      <Flex
        align="center"
        justify="center"
        bg={isActive ? 'brand.600' : '#fff'}
        borderRadius="100%"
        w="36px"
        h="36px"
        color={isActive ? '#fff' : '#000'}
        fontSize="lg"
        boxShadow="rgba(0,0,0, .8) 0 0 6px"
      >
        {Icon && <Icon />}
        {content}
      </Flex>
    </ThemeProvider>
  );
};

export const createSimpleMapIcon = (
  content: any,
  isActive: boolean = false
) => {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <MarkerIcon content={content} isActive={isActive} />
    ),
    iconSize: [38, 38],
    iconAnchor: [19, 19],
    popupAnchor: [0, -19],
  });
};

export const createMapIcon = (icon: any, isActive: boolean = false) => {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <MarkerIcon Icon={icon} isActive={isActive} />
    ),
    iconSize: [38, 38],
    iconAnchor: [19, 19],
    popupAnchor: [0, -19],
  });
};

export const createMerchantIcon = (fill = '#58b300') => {
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <MerchantMapMarkerIcon color={fill} width="29px" height="38px" />
    ),
    iconSize: [29, 38],
    iconAnchor: [14, 19],
    popupAnchor: [0, -19],
  });
};
