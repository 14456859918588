import { SimpleGrid } from '@chakra-ui/react';
import React from 'react';

const TeaserGrid: React.FC<{ columns?: number; spacing?: number }> = ({
  columns,
  spacing,
  children,
}) => {
  return (
    <SimpleGrid
      columns={{
        base: 1,
        md: columns || 3,
      }}
      spacing={spacing || 8}
    >
      {children}
    </SimpleGrid>
  );
};

export default TeaserGrid;
