import stripHtml from 'string-strip-html';

export default function truncateText(
    html: string,
    maxLength: number = 200,
    truncateText: string = '...'
){
    if (!html) return '';
    let strippedData = stripHtml(html);

    if (strippedData.length > maxLength) {
        strippedData = strippedData.substring(
            0,
            maxLength - truncateText.length
        );

        strippedData += truncateText;
    }

    return strippedData;
};
