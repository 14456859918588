import { Heading, Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useStore } from '../../store/provider';

interface ParagraphTitleProps {
  label: String;
  sub?: String | null;
  ActionComponent?: JSX.Element;
}

const ParagraphTitle: React.FC<ParagraphTitleProps> = ({
  label,
  sub,
  ActionComponent,
}) => {
  const store = useStore();

  return (
    <Flex
      flex={1}
      justifyContent="space-between"
      alignItems={{
        base: ActionComponent ? 'flex-start' : 'center',
        md: 'center',
      }}
      flexDir={{
        base: ActionComponent ? 'column' : 'row',
        md: 'row',
      }}
    >
      {store.config.usesNewLayout ? (
        <>
          <Heading mb={4} fontSize="2xl">
            <Box>{label}</Box>
            {sub && <Box ml={2}>{sub}</Box>}
          </Heading>
        </>
      ) : (
        <>
          <Heading as="h3" fontSize="2xl" flex={1}>
            <Box
              as="span"
              color="textColor"
              borderBottomColor="textColor"
              borderBottom="1px"
            >
              {label}
            </Box>
            <Box marginLeft={2} as="span">
              {sub}
            </Box>
          </Heading>
        </>
      )}

      {ActionComponent && <Box>{ActionComponent}</Box>}
    </Flex>
  );
};

export default ParagraphTitle;
