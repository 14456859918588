import React from 'react';
import dynamic from 'next/dynamic';
import {
  ActivityTeaserFragmentFragment,
  HotelTeaserFragmentFragment,
  PoiTeaserFragmentFragment,
} from '../generated/types';
import { getAppComponentByAppId } from '../utils/getAppComponent';

const TEASER_MAPPING: {
  [index: string]: React.ComponentType<{
    data:
      | HotelTeaserFragmentFragment
      | ActivityTeaserFragmentFragment
      | PoiTeaserFragmentFragment;
  }>;
} = {
  Hotel: getAppComponentByAppId(process.env.APP_ID!, 'HotelTeaser'),
  Activity: getAppComponentByAppId(process.env.APP_ID!, 'ActivityTeaser'),
  Poi: getAppComponentByAppId(process.env.APP_ID!, 'PoiTeaser'),
};

export default TEASER_MAPPING;
