import { Component, FC } from 'react';
import React from 'react';
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Img,
  ImgProps,
  ResponsiveValue,
} from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import { HTMLText } from './HTMLText';
import Link from 'next/link';
import { useStore } from '../../store/provider';
import getIconForCategoryKey from '../../utils/getIconForCategoryKey';
import useIsMobile from '../../hooks/useIsMobile';

interface EbikeAtlasTeaserProps {
  image?: string;
  categoryKey?: string;
  title?: string;
  region?: string;
  country?: string;
  text?: string;
  url?: string;
  imageAlt?: string;
  imageTitle?: string;
  type?: 'hotel' | 'activity' | 'poi' | 'product' | 'news';
  disableLazy?: boolean;
  small?: boolean;
  textLength?: number;
  vertical?: boolean;
  onImageLoaded?: () => void;
  noMeta?: boolean;
  ctaText?: string;
  imageWidth?: number;
  imageHeight?: number;
  imageProps?: ImgProps;
  centerImage?: boolean;
  customMeta?: JSX.Element;
  customText?: JSX.Element;
  imageContainerMaxWidth?: string;
  noOfLines?: number | ResponsiveValue<number>;
  hideCTA?: boolean;
  withMobileVersion?: boolean;
  smallMapMarker?: boolean;
  isPass?: boolean;
}

const EbikeAtlasTeaser: FC<EbikeAtlasTeaserProps> = ({
  image,
  categoryKey,
  title,
  region,
  country,
  text,
  url,
  imageAlt,
  imageTitle,
  type,
  textLength,
  small,
  disableLazy,
  vertical,
  onImageLoaded,
  noMeta,
  ctaText,
  imageWidth = 600,
  imageHeight = 300,
  imageProps,
  centerImage = false,
  customMeta,
  noOfLines,
  imageContainerMaxWidth = '600px',
  hideCTA,
  withMobileVersion,
  smallMapMarker,
  isPass,
  customText,
  ...props
}) => {
  const store = useStore();
  const isMobile = useIsMobile();
  const fallbackImage =
    type === 'activity' &&
    store.backendConfig.defaultActivityImage &&
    store.backendConfig.defaultActivityImage.teaser.url;
  const Icon = categoryKey ? getIconForCategoryKey(categoryKey) : null;

  const ImgEl = (
    <Img
      borderRadius="lg"
      alt={imageAlt || ''}
      title={imageTitle || ''}
      htmlWidth={imageWidth}
      htmlHeight={imageHeight}
      width="100%"
      src={image || fallbackImage!}
      onLoad={onImageLoaded}
      {...imageProps}
    />
  );

  const Image = centerImage ? (
    ImgEl
  ) : (
    <AspectRatio ratio={imageWidth / imageHeight}>{ImgEl}</AspectRatio>
  );

  const useVertical = vertical || isMobile || small;

  let fallbackText = 'Mehr erfahren';

  if (type === 'hotel' && store.config.newLayout?.cta?.hotelTeaser) {
    fallbackText = store.config.newLayout?.cta?.hotelTeaser;
  } else if (
    type === 'activity' &&
    store.config.newLayout?.cta?.activityTeaser
  ) {
    fallbackText = store.config.newLayout?.cta?.activityTeaser;
  } else if (type === 'news' && store.config.newLayout?.cta?.newsTeaser) {
    fallbackText = store.config.newLayout?.cta?.newsTeaser;
  }

  return (
    <Flex
      width="100%"
      bg="#fff"
      p={small ? 2 : 0}
      flexDirection={useVertical ? 'column' : 'row'}
    >
      {!smallMapMarker && (
        <Box
          maxWidth={{
            base: '100%',
            md: '300px',
            lg: imageContainerMaxWidth,
          }}
          width="100%"
          flex="1 0 100%"
        >
          <Link href={url}>
            <a>
              {(image || fallbackImage) &&
                (disableLazy ? (
                  Image
                ) : (
                  <LazyLoad height={300}>{Image}</LazyLoad>
                ))}
              {!image && !fallbackImage && (
                <Box
                  w="100%"
                  height={300}
                  borderRadius="lg"
                  bgColor="gray.200"
                />
              )}
            </a>
          </Link>
        </Box>
      )}

      <Box
        marginTop={{
          base: useVertical ? (small ? 1 : 2) : 0,
          md: useVertical ? (small ? 1 : 4) : 0,
        }}
        marginLeft={useVertical ? 0 : 8}
      >
        <Flex align="center">
          {Icon && !vertical && !small && (
            <Box
              padding={small ? 1 : 2}
              borderRadius="lg"
              display="inline-flex"
              fontSize={{
                base: small ? 'sm' : 'md',
                md: small ? 'md' : 'xl',
              }}
              border="2px solid"
              borderColor="textColorBase"
              color="textColor"
              mr={2}
            >
              <Icon />
            </Box>
          )}

          <Box
            color="textColor"
            fontWeight="bold"
            fontSize={small ? 'lg' : 'xl'}
          >
            <Link passHref href={url}>
              <Box as="a" color="textColor">
                {title}
              </Box>
            </Link>
          </Box>
        </Flex>

        {!noMeta && (country || region) && (
          <Box
            marginTop={0.5}
            fontWeight="medium"
            fontSize={{
              base: 'xs',
              md: 'sm',
            }}
          >
            {country} {region && `/ ${region}`}
          </Box>
        )}
        {customMeta}
        {type !== 'news' &&
          !withMobileVersion &&
          ((text && !smallMapMarker) || customText) && (
            <Box
              marginY={{
                base: 1,
                md: 2,
              }}
              width="60px"
              height="2px"
              bg="brand.600"
            />
          )}
        {text && !smallMapMarker && (
          <Box
            marginTop={{
              base: small ? 1 : 2,
              md: small ? 1 : 3,
            }}
            fontSize={small ? 'sm' : 'md'}
            display={{
              base: withMobileVersion ? 'none' : 'block',
              md: 'block',
            }}
            wordBreak="break-word"
          >
            <HTMLText
              noOfLines={
                noOfLines || {
                  base: 4,
                  md: 5,
                }
              }
              text={text}
            />
          </Box>
        )}
        {customText}
        {!hideCTA && !withMobileVersion && (
          <Box
            marginTop={{
              base: small ? 1 : 3,
              md: small ? 2 : 6,
            }}
          >
            <Link href={url}>
              <a>
                <Button
                  as="span"
                  width={{
                    base: '100%',
                    md: 'auto',
                  }}
                  size={small ? 'xs' : 'md'}
                >
                  {ctaText || fallbackText}
                </Button>
              </a>
            </Link>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default EbikeAtlasTeaser;
