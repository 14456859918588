import { Box, BoxProps } from '@chakra-ui/react';

type WrapperProps = BoxProps & {};

const Wrapper: React.FC<WrapperProps> = ({ children, ...props }) => {
    return (
        <Box marginY={12} {...props}>
            {children}
        </Box>
    );
};

export default Wrapper;
