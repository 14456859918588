import {
  BedIcon,
  BikeIcon,
  EbikeIcon,
  GolfIcon,
  HikingIcon,
  KanuIcon,
  MotorcycleIcon,
  MountainBikeIcon,
  PoiIcon,
  PoiPassIcon,
  RidingIcon,
  SkiIcon,
  SwimIcon,
} from '../config/icons';

export default function getIconForCategoryKey(key: string) {
  switch (key) {
    case 'hotel':
      return BedIcon;
    case 'motorcycle':
    case 'pass':
    case 'tour':
    case 'offroad':
      return MotorcycleIcon;
    case 'ebike':
      return EbikeIcon;
    case 'bike':
      return BikeIcon;
    case 'hiking':
    case 'walk':
      return HikingIcon;
    case 'poi':
      return PoiIcon;
    case 'golf':
      return GolfIcon;
    case 'ski':
      return SkiIcon;
    case 'swim':
      return SwimIcon;
    case 'riding':
      return RidingIcon;
    case 'kanu':
      return KanuIcon;
    case 'mountainbike':
      return MountainBikeIcon;
    case 'poi_pass':
      return PoiPassIcon;

    default:
      return null;
  }
}
