import React, { FC } from 'react';
import { Box, BoxProps, ResponsiveValue } from '@chakra-ui/react';
import truncateText from '../../utils/truncate-text';
import { API_ENDPOINT } from '../../config/constants';
import stripHtml from 'string-strip-html';

type HTMLTextProps = BoxProps & {
  text: string;
  unstyled?: boolean;
  stripTags?: boolean;
  truncate?: number;
  noOfLines?: number | ResponsiveValue<number>;
  transformImages?: boolean;
};

export const HTMLText: FC<HTMLTextProps> = ({
  text,
  unstyled,
  truncate,
  noOfLines,
  transformImages,
  ...props
}) => {
  const classes = ['html-text'];

  if (unstyled) classes.push('unstyled');

  if (truncate) {
    text = truncateText(text, truncate);
  } else {
    if (transformImages) {
      text = text.replace(
        new RegExp(/<img src="\/sites\/default\/files/gi),
        `<img src="${API_ENDPOINT}/sites/default/files`
      );
    }
  }

  if (noOfLines) {
    text = stripHtml(text);
  }

  return (
    <Box
      className={classes.join(' ')}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      noOfLines={noOfLines}
      __css={{
        p: {
          mb: 2,
        },
        a: {
          color: 'textColor',
        },
        ul: {
          marginLeft: 2,
          li: {
            marginLeft: 4,
          },
        },
      }}
      {...props}
    />
  );
};
