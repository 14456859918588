import { TeaserMarkerDataType } from '../components/Common/TeaserMarker';
import TEASER_MAPPING from '../config/teaser-mapping';
import React from 'react';

export default function renderTeaser(data: TeaserMarkerDataType, props?: any) {
  if (!data) return null;

  const Component = TEASER_MAPPING[data.__typename] || null;
  if (Component) {
    return <Component key={data.id} data={data} {...props} />;
  }

  return null;
}
