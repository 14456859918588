import { LatLng, LatLngBounds } from 'leaflet';
import { axiosInstanceWithoutAuth } from './api';

export default async function parseGPX(filePath: string) {
  // TODO: add caching of gpx
  try {
    const response = await axiosInstanceWithoutAuth.get(filePath, {
      withCredentials: false,
    });

    if (response.status === 200 && response.data) {
      const points = [];
      const parser = new DOMParser();
      const gpx = parser.parseFromString(response.data, 'text/xml');

      if (gpx) {
        const tracks = gpx.getElementsByTagName('trk');

        // find correct track
        let track = null;

        for (let i = 0; i < tracks.length; i++) {
          const t = tracks[i];

          const names = t.getElementsByTagName('name');
          if (names.length) {
            const name = names[0];

            if (name.textContent === 'Route') {
              continue;
            }
          }

          track = t;
        }

        if (track) {
          const segments = track.getElementsByTagName('trkseg');
          for (let i = 0; i < segments.length; i++) {
            const segment = segments[i];
            const pointsOfSegment = segment.getElementsByTagName('trkpt');

            for (let j = 0; j < pointsOfSegment.length; j++) {
              const point = pointsOfSegment[j];

              const lat = point.getAttribute('lat');
              const lng = point.getAttribute('lon');

              if (lat && lng) {
                points.push(new LatLng(parseFloat(lat), parseFloat(lng)));
              }
            }
          }
        }
      }

      if (points.length) {
        const bounds = new LatLngBounds(points[0], points[0]);
        points.forEach((p) => bounds.extend(p));

        return {
          points,
          bounds,
        };
      }
    }
  } catch (err) {}

  return null;
}
