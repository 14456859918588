export default function getIconColorForCategoryKey(key: string): string {
    switch (key) {
        case 'hotel':
            return 'iconHotel';
        case 'motorcycle':
        case 'tour':
        case 'poi':
            return 'iconMotorcycle';
        case 'ebike':
            return 'iconEbike';
        case 'bike':
            return 'iconBike';
        case 'hiking':
        case 'walk':
            return 'iconHiking';
        case 'golf':
            return 'iconGolf';
        case 'ski':
            return 'iconSki';
        case 'swim':
            return 'iconSwim';
        case 'riding':
            return 'iconRiding';
        case 'kanu':
            return 'iconKanu';
        case 'mountainbike':
            return 'iconMountainbike';

        default:
            return '';
    }
}
