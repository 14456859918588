import { createContext } from 'react';
import { NodeFragmentType } from '../types/global-types';

interface EntityContextType {
  __typename?: string;
  entity: NodeFragmentType;
}

const EntityContext = createContext<EntityContextType>(null);
export default EntityContext;
