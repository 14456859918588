import { Img, Box, ImgProps, Tooltip } from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';

type CopyrightImageProps = ImgProps & {
  copyright?: string;
  copyrightPosition?: 'top' | 'bottom';
  copyrightOffset?: number | string;
  lazy?: boolean;
};

const CopyrightImage: React.FC<CopyrightImageProps> = ({
  copyright,
  copyrightPosition = 'bottom',
  copyrightOffset = 0,
  lazy,
  ...rest
}) => {
  const copyrightText = `Bild: ${copyright}`;

  return (
    <>
      {lazy ? (
        <LazyLoad>
          <Img {...rest} />
        </LazyLoad>
      ) : (
        <Img {...rest} />
      )}
      {copyright && (
        <Tooltip hasArrow aria-label={copyrightText} label={copyrightText}>
          <Box
            backgroundColor="rgba(0,0,0, .4)"
            color="#fff"
            fontSize="xs"
            zIndex={9}
            position="absolute"
            bottom={copyrightPosition === 'bottom' ? copyrightOffset : 'auto'}
            top={copyrightPosition === 'top' ? copyrightOffset : 'auto'}
            right="0"
            paddingY={1}
            paddingX={2}
          >
            ©
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default CopyrightImage;
