import {
  BedIcon,
  BikeIcon,
  EbikeIcon,
  GolfIcon,
  HikingIcon,
  KanuIcon,
  MotorcycleIcon,
  PoiIcon,
  RidingIcon,
  SkiIcon,
  SwimIcon,
  MerchantIcon,
  PoiPassIcon,
} from '../config/icons';
import { createMapIcon, basicIcon } from '../config/map-icons';

export default function getMapIconForCategoryKey(
  key: string,
  isActive: boolean = false
) {
  switch (key) {
    case 'hotel':
      return createMapIcon(BedIcon, isActive);
    case 'merchant':
      return createMapIcon(MerchantIcon, isActive);
    case 'offroad':
    case 'tour':
    case 'pass':
    case 'etappe':
    case 'motorcycle':
      return createMapIcon(MotorcycleIcon, isActive);
    case 'ebike':
      return createMapIcon(EbikeIcon, isActive);
    case 'bike':
      return createMapIcon(BikeIcon, isActive);
    case 'poi':
      return createMapIcon(PoiIcon, isActive);
    case 'hiking':
      return createMapIcon(HikingIcon, isActive);
    case 'golf':
      return createMapIcon(GolfIcon, isActive);
    case 'ski':
      return createMapIcon(SkiIcon, isActive);
    case 'swim':
      return createMapIcon(SwimIcon, isActive);
    case 'riding':
      return createMapIcon(RidingIcon, isActive);
    case 'kanu':
      return createMapIcon(KanuIcon, isActive);

    case 'poi_pass':
      return createMapIcon(PoiPassIcon, isActive);

    default:
      return basicIcon;
  }
}
